<template>
  <div class="aboutHelpPage">
    <h4 class="header">当前位置： 使用帮助 / 帮助说明</h4>
    <a-card :bordered="false">
        <div class="textarea" v-html="help"></div>
    </a-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import setting from "@/config/setting";
export default {
  name: "AboutHelp",
  components: {
    
  },
  data() {
    return {
      help:'',
    };
  },
  created() {
    this.getHelp();
  },
  methods: {
    async getHelp(){
      try{
        let res = await this.$http.get(this.$interface.myGetHelp);
        this.help = res.data.data || '';
      }catch(err){}
    }
  },
  computed: {
  },
};
</script>